<template>
  <div>
    <el-button type="danger" plain size="medium" @click="showConfirm = true"
      >Cancel Order</el-button
    >
    <eden-confirm-dialog
      title="Cancel Order"
      :button-text="'Cancel'"
      :button-type="'success'"
      :button-status="cancelling"
      :show.sync="showConfirm"
      @confirm="cancel"
    >
      <p>Are you sure you want to cancel this order?</p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import order from "@/requests/orders/order";

export default {
  name: "OrderCancellation",
  props: {
    service: {
      type: String,
      default: "",
    },
    orderId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      showConfirm: false,
      cancelling: false,
    };
  },
  methods: {
    cancel() {
      this.cancelling = true;
      const service = this.service.includes("meal") ? "meal" : this.service;
      order
        .cancel(service, this.orderId)
        .then((response) => {
          if (response.data.status) {
            this.showConfirm = false;
            this.cancelling = false;
            this.$message.success(response.data.message);
          }
        })
        .catch((error) => {
          this.cancelling = false;
          this.$message.success(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss"></style>
