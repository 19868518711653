<template>
  <el-dialog
    :title="`${feedback ? 'Edit' : 'Give'} extra feedback`"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
    append-to-body
  >
    <el-form :model="form" label-position="top" ref="clusterAddForm">
      <el-form-item label="Extra feedback">
        <el-input type="textarea" :rows="3" v-model="form.feedback" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="shouldShow = false">Cancel</el-button>
      <el-button
        type="primary"
        @click="updateText"
        :disabled="!form.feedback"
        >{{ feedback ? "Save" : "Add" }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "OrderFeedbackExtra",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        feedback: "",
      },
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.feedback = this.feedback;
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    updateText() {
      this.$emit("success", this.form.feedback);
      this.closeEvent();
    },
  },
};
</script>

<style lang="scss" scoped></style>
