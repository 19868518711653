<template>
  <div class="order-feedback" v-loading="loading">
    <div v-if="!feedback.status" class="empty">
      <span class="font-base"> No feedback given for this order.</span>
      <el-button
        type="primary"
        :disabled="disableAdd"
        @click="showOrderFeedbackAdd = true"
        >Add Customer Feedback</el-button
      >
    </div>
    <eden-overview-card v-else :title="'Feedback'">
      <template slot="content">
        <div class="overview-card">
          <div
            v-for="(item, index) in feedback.specific"
            :key="index"
            class="feedback-item"
          >
            <div class="feedback-item__title">
              <img
                v-if="item.rating"
                class="icon"
                :src="getRatingImage(item.rating)"
                :alt="item.rating"
              />
              <span class="font-sm">{{ item.name }}</span>
            </div>
            <div v-if="item.reasons.length" class="feedback-item__reasons">
              <el-tag
                v-for="(reason, index) in item.reasons"
                :key="index"
                type="info"
              >
                {{ reason }}
              </el-tag>
            </div>
            <div v-if="item.comment" class="feedback-item__comment">
              <span class="font-sm">Extra Comments</span>
              <span class="font-sm">{{ item.comment }}</span>
            </div>
          </div>
          <div class="feedback-item">
            <div class="feedback-item__title">
              <img
                class="icon"
                :src="getRatingImage(feedback.general.rating)"
                :alt="feedback.general.rating"
              />
              <span class="font-sm">General Experience</span>
            </div>
            <div
              v-if="feedback.general.reasons.length"
              class="feedback-item__reasons"
            >
              <el-tag
                v-for="(reason, index) in feedback.general.reasons"
                :key="index"
                type="info"
              >
                {{ reason }}
              </el-tag>
            </div>
            <div v-if="feedback.general.comment" class="feedback-item__comment">
              <span class="font-sm">Extra Comments</span>
              <span class="font-sm">{{ feedback.general.comment }}</span>
            </div>
          </div>
        </div>
      </template>
    </eden-overview-card>
    <order-feedback-add
      :show.sync="showOrderFeedbackAdd"
      :order="order"
      :service="service"
      @success="getFeedback"
    />
  </div>
</template>

<script>
import OrderFeedbackAdd from "@/components/Orders/Order/Feedback/OrderFeedbackAdd";
import feedback from "@/requests/orders/feedback";

export default {
  name: "OrderFeedback",
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    service: {
      type: String,
      default: "",
    },
  },
  components: {
    OrderFeedbackAdd,
  },
  data() {
    return {
      loading: false,
      feedback: {
        status: false,
        service: "",
        general: {},
        specific: [],
      },
      showOrderFeedbackAdd: false,
    };
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    disableAdd() {
      return (
        this.service === "laundry" &&
        this.order.items_tally &&
        this.order.items_tally.length === 0
      );
    },
  },
  created() {
    this.getFeedback();
  },
  methods: {
    getFeedback() {
      this.loading = true;
      feedback
        .get(this.orderId)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.setFeedback(data);
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setFeedback(items) {
      if (!items.length) {
        this.loading = false;
        return;
      }
      let feedback = items[0];
      const rating = feedback.general_experience_rating;
      const comment = feedback.general_experience_feedback;
      const reasons = feedback.general_experience_reasons;

      this.feedback.service = feedback.service;
      this.feedback.general = {
        rating,
        comment,
        reasons,
      };
      this.feedback.specific = items.map((item) => {
        return {
          name:
            item.service === "meal"
              ? this.formatMealName(item.commodity)
              : this.formatToTitleCase(item.commodity),
          rating: item.commodity_rating,
          reasons: item.commodity_reasons,
          comment: item.commodity_extra_feedback,
        };
      });

      this.feedback.status = true;
      this.loading = false;
    },
    getRatingImage(rating) {
      return this.getImage(
        `${rating === "good" ? "positive" : "negative"}.svg`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.order-feedback {
  .empty {
    text-align: center;
    padding: 24px 0;

    > * {
      margin: 15px auto;
    }
  }
  .feedback-item {
    margin-bottom: 16px;
    padding: 14px;
    border: 1px solid #f0f4f2;
    border-radius: 8px;
    color: #0f241b;

    &__title {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      span {
        font-size: 0.875rem;
        font-weight: 500;
        color: var(--eden-grey-primary);
      }
    }

    &__reasons {
      margin-top: 10px;

      .el-tag {
        margin-bottom: 7px;
      }
    }

    &__comment {
      margin-top: 20px;

      span {
        font-size: 0.875rem;
        color: var(--eden-grey-primary);
      }
      span:first-child {
        display: block;
        font-weight: 500;
        margin-bottom: 3px;
      }
    }
  }
}
</style>
