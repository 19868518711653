<template>
  <div class="is-flex">
    <el-tag :class="'small'" :type="setType(orderStatus)">
      {{ formatText(orderStatus) }}
    </el-tag>
    <el-tag v-if="orderCurrentCycle" :class="'small'" :type="'info'">
      Current Cycle
    </el-tag>
    <el-tag v-if="orderOnetime" type="info">{{
      orderComplementary ? "Complementary" : "One-time Order"
    }}</el-tag>
  </div>
</template>

<script>
export default {
  name: "OrderTags",
  props: {
    orderStatus: {
      type: String,
      default: "",
    },
    orderCurrentCycle: {
      type: Boolean,
      default: false,
    },
    orderOnetime: {
      type: Boolean,
      default: false,
    },
    orderComplementary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
