import { mealIndex } from "@/components/Orders/Order/Meal/meal.config";
import { BEAUTY_ITEMS } from "@/components/Orders/Order/Beauty/beauty.config";

export const formatFeedbackItems = (order, service) => {
  switch (service) {
    case "beauty":
      return beauty(order);
    case "cleaning":
      return cleaning(order);
    case "laundry":
      return laundry(order);
    case "meal":
      return meals(order);
    default:
      return [];
  }
};

const beauty = (order) => {
  let items = [];
  BEAUTY_ITEMS().forEach((item) => {
    const variation = order[`${item}_item`];
    const variationImage = order[`${item}_item_img`];

    if (variation) {
      items.push({
        name: variation,
        image: variationImage,
      });
    }
  });

  return items;
};

const cleaning = (order) => {
  let items = [];
  let item_areas = order.item_areas
    ? order.item_areas
    : order.subscription_plan.cleaning.item_areas ?? null;

  if (item_areas) {
    Object.keys(item_areas).forEach((area) => {
      if (item_areas[area]) {
        items.push({
          name: area,
          image: null,
        });
      }
    });
  }

  return items;
};

const laundry = (order) => {
  return order.items_tally.map((item) => {
    return {
      name: item.item,
      quantity: item.count,
      image: null,
    };
  });
};

const meals = (order) => {
  let items = [];
  mealIndex().forEach((item) => {
    const mealItem = order[`${item}_combo`];
    const mealImage = order[`${item}_combo_img`];
    const mealName = mealItem ? mealItem : null;

    if (mealItem) {
      items.push({
        name: mealName,
        image: mealImage,
      });
    }
  });

  return items;
};
