<template>
  <div>
    <el-form-item label="Last update">
      <div class="order-audits">
        <p class="text-grey-secondary">
          {{ formatDate(recentUpdate.date, "ddd do, m y") }} -
          {{ formatTime(recentUpdate.date, "12h") }}
        </p>
        <p>
          by
          <span class="text-grey-primary text-bold">{{
            recentUpdate.name
          }}</span>
        </p>
        <p class="text-primary" @click="getAudits">View update history</p>
      </div>
    </el-form-item>
    <el-drawer :visible.sync="showAudits" direction="rtl" size="28%">
      <template slot="title">
        <span>Update History</span>
      </template>
      <div class="el-drawer--content">
        <div
          v-if="loading"
          v-loading="loading"
          :style="{ height: '100px' }"
        ></div>
        <template v-else>
          <el-timeline
            v-if="audits.length"
            class="order-audits__timeline"
            :reverse="true"
          >
            <el-timeline-item
              v-for="(audit, index) in audits"
              :key="index"
              :timestamp="audit.timestamp"
              :hide-timestamp="true"
            >
              <p class="text-grey-secondary">
                {{ audit.action }} by
                <span class="text-grey-primary text-medium">{{
                  audit.performer
                }}</span>
              </p>
              <span class="text-grey-tertiary"
                >{{ formatDate(audit.date, "dddd do, m, y") }} -
                {{ formatTime(audit.date, "12h") }}</span
              >
            </el-timeline-item>
          </el-timeline>
          <div v-else class="is-flex is-justify-center is-align-center">
            <p>No logs</p>
          </div>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import orders from "@/requests/orders/order";

export default {
  name: "OrderAudits",
  props: {
    recentUpdate: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      showAudits: false,
      audits: [],
    };
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
  },

  methods: {
    getAudits() {
      this.loading = true;
      this.audits = [];
      this.showAudits = true;
      orders
        .audits(this.orderId)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            const { service_order, customer_order } = data;
            const audits = service_order
              .concat(customer_order)
              .filter((audit) => {
                const { new_values } = audit;
                if (!new_values) {
                  return audit;
                }
                if (new_values && !new_values.recently_updated_by) {
                  return audit;
                }
              })
              .map((audit) => this.formatLog(audit));

            this.audits = audits.reduce((unique, o) => {
              if (
                !unique.some(
                  (obj) => obj.action === o.action && obj.date === o.date,
                )
              ) {
                unique.push(o);
              }
              return unique;
            }, []);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    formatLog(log) {
      let action = "";
      const { event, new_values, url } = log;

      const created = event === "created";
      const reschedule_pickup = new_values && new_values.pickup_from_customer;
      const reschedule_delivery = new_values && new_values.deliver_to_customer;
      const completed = new_values && new_values.completed_status;
      const confirmed = new_values && new_values.is_confirmed;
      const cancelled = event.includes("cancelled");
      const updated = url.includes("/orders/");
      const recommendations = url.includes("recommendations");
      const cleaners_assigned = url.includes("assign_cleaners");
      const laundry_tally = url.includes("laundry_tally");

      if (created) {
        action = "Order created";
      } else if (reschedule_pickup) {
        action = "Order rescheduled (Pickup)";
      } else if (reschedule_delivery) {
        action = "Order rescheduled (Delivery)";
      } else if (completed) {
        action = "Order completed";
      } else if (confirmed) {
        action = "Order confirmed";
      } else if (cancelled) {
        action = "Order cancelled";
      } else if (updated) {
        action = "Order updated";
      } else if (recommendations) {
        action = "Order recommendations";
      } else if (cleaners_assigned) {
        action = "Cleaners assigned";
      } else if (laundry_tally) {
        action = "Tally Items added";
      }

      return {
        action,
        performer: log.user ? log.user.name : "System",
        date: log.updated_at,
        time: this.formatTime(log.updated_at, "12h"),
      };
    },
  },
};
</script>

<style lang="scss">
.order-audits {
  p {
    font-size: 0.875rem;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:last-child {
      display: inline;
      cursor: pointer;
      margin: 15px 0 10px;
    }
  }
}

.order-audits__timeline {
  margin: 0;
  padding: 0;

  .el-timeline-item__node--normal {
    height: 6px;
    width: 6px;
    left: 2px;
    background: var(--eden-grey-quaternary);
  }

  .el-timeline-item__tail {
    height: 80%;
    top: 10px;
    border-left: 1px solid #e2e9e6;
  }

  .el-timeline-item__content {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--eden-grey-septenary);

    p,
    span {
      font-size: 0.875rem;
    }
  }
}
</style>
