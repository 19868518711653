<template>
  <div>
    <el-drawer :visible.sync="shouldShow" direction="rtl" @close="closeEvent">
      <template slot="title">
        <span class="font-lg">Add Customer Feedback</span>
      </template>
      <div class="el-drawer--content">
        <el-form :model="form">
          <template v-if="step === 1 && form.feedback.length">
            <div class="el-drawer--content__section" :key="2">
              <p class="title">How did your customer find their service?</p>
              <div class="item" v-for="(item, index) in items" :key="index">
                <div class="item-info">
                  <div class="item-title">
                    <img :src="getImage(item.image)" alt="" />
                    <p>{{ getItemLabel(item.name) }}</p>
                  </div>
                  <div class="item-rating">
                    <el-radio-group
                      v-model="form.feedback[index].rating"
                      class="eden-custom--radio flex circle"
                    >
                      <el-radio label="good">
                        <img :src="getImage('positive.svg')" alt="" />
                      </el-radio>
                      <el-radio label="bad">
                        <img :src="getImage('negative.svg')" alt="" />
                      </el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div class="item-content" v-if="form.feedback[index].rating">
                  <el-form-item>
                    <el-checkbox-group
                      v-model="form.feedback[index].reasons"
                      class="eden-custom--checkbox flex"
                    >
                      <el-checkbox
                        v-for="(reason, key) in ratingReasons[
                          `peculiar_${form.feedback[index].rating}`
                        ]"
                        :key="key"
                        :label="reason"
                        class="small"
                        >{{ reason }}</el-checkbox
                      >
                    </el-checkbox-group>
                  </el-form-item>
                  <div class="item-extra--feedback">
                    <span
                      v-if="form.feedback[index].extra_feedback"
                      @click="editExtraFeedback(index)"
                      >Edit this comment</span
                    >
                    <p v-else>
                      Did they mention anything else?
                      <span @click="addExtraFeedback(index)"
                        >Add extra feedback
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="step === 2">
            <div class="el-drawer--content__section" :key="1">
              <p class="title">How was their general experience?</p>
              <el-form-item>
                <div class="general-experience">
                  <el-radio-group
                    v-model="form.general_experience.rating"
                    class="eden-custom--radio circle"
                    @change="form.general_experience.reasons = []"
                  >
                    <el-radio label="good">
                      <img :src="getImage('positive.svg')" alt="" />
                    </el-radio>
                    <el-radio label="bad">
                      <img :src="getImage('negative.svg')" alt="" />
                    </el-radio>
                  </el-radio-group>
                </div>
              </el-form-item>
              <el-form-item>
                <el-checkbox-group
                  v-if="form.general_experience.rating === 'good'"
                  v-model="form.general_experience.reasons"
                  class="eden-custom--checkbox flex"
                >
                  <el-checkbox
                    v-for="(reason, key) in ratingReasons.good"
                    :key="key"
                    :label="reason"
                    class="small"
                    >{{ reason }}</el-checkbox
                  >
                </el-checkbox-group>
                <el-checkbox-group
                  v-if="form.general_experience.rating === 'bad'"
                  v-model="form.general_experience.reasons"
                  class="eden-custom--checkbox flex"
                >
                  <el-checkbox
                    v-for="(reason, key) in ratingReasons.bad"
                    :key="key"
                    :label="reason"
                    class="small"
                    >{{ reason }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item>
                <div class="item mt-1">
                  <div class="item-extra--feedback text-center">
                    <span
                      v-if="form.general_experience.extra_feedback"
                      @click="editExtraFeedback"
                      >Edit this comment</span
                    >
                    <p v-else>
                      Did they mention anything else?
                      <span @click="addExtraFeedback">Add extra feedback </span>
                    </p>
                  </div>
                </div>
              </el-form-item>
            </div>
          </template>
        </el-form>
      </div>
      <div class="el-drawer--footer is-justify-between">
        <el-button
          type="outline"
          plain
          icon="el-icon-back"
          :style="{ opacity: step > 1 ? 1 : 0 }"
          @click="step = 1"
          >Back</el-button
        >
        <el-button
          v-if="step === 1"
          type="primary"
          :disabled="!form.feedback.length"
          @click="step = 2"
          >Next</el-button
        >
        <el-button
          v-else
          type="primary"
          :loading="submitting"
          :disabled="form.general_experience.rating === ''"
          @click="submit"
          >Submit</el-button
        >
      </div>
    </el-drawer>
    <order-feedback-comment
      :show.sync="showOrderFeedbackComment"
      :feedback="item.feedback"
      @success="updateExtraFeedback"
    />
  </div>
</template>

<script>
import OrderFeedbackComment from "./OrderFeedbackComment";

import feedback from "@/requests/orders/feedback";
import { formatFeedbackItems } from "./feedback.config";

export default {
  name: "OrderFeedbackAdd",
  components: { OrderFeedbackComment },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    service: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: [],
      ratingReasons: [],
      step: 1,
      form: {
        general_experience: {
          rating: "good",
          reasons: [],
          extra_feedback: "",
        },
        feedback: [],
      },
      item: {
        index: null,
        feedback: "",
      },
      showOrderFeedbackComment: false,
      submitting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getRatingReasons();
        this.setFeedbackItems();
      }
    },
  },
  methods: {
    setFeedbackItems() {
      this.items = formatFeedbackItems(this.order, this.service);
      if (this.items.length) {
        this.items.forEach((item) => {
          this.form.feedback.push({
            name: item.name,
            image: item.image,
            rating: "",
            reasons: [],
            extra_feedback: "",
          });
        });
      }
    },
    addExtraFeedback(index) {
      if (this.step === 1) {
        this.item = { index, feedback: "" };
      }
      this.showOrderFeedbackComment = true;
    },
    editExtraFeedback(index) {
      if (this.step === 1) {
        this.item = {
          index,
          feedback: this.form.feedback[index].extra_feedback,
        };
      } else {
        this.item.feedback = this.form.general_experience.extra_feedback;
      }
      this.showOrderFeedbackComment = true;
    },
    updateExtraFeedback(feedback) {
      if (this.step === 1) {
        this.form.feedback[this.item.index].extra_feedback = feedback;
      } else {
        this.form.general_experience.extra_feedback = feedback;
      }
    },
    payload() {
      return {
        service: this.service,
        user_id: this.order.customer_id,
        customer_order_id: this.$route.params.id,
        general_experience: this.form.general_experience,
        feedback: this.form.feedback,
      };
    },
    submit() {
      this.submitting = true;
      const payload = this.payload();
      feedback
        .add(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.submitting = false;
            this.closeEvent();
          } else {
            this.submitting = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.submitting = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    closeEvent() {
      this.shouldShow = false;
    },
    getRatingReasons() {
      feedback.reasons(this.service).then((response) => {
        if (response.data.data.length) {
          this.ratingReasons = response.data.data[0];
        } else {
          this.$message.error("Error retrieving Feedback Information.");
        }
      });
    },
    getItemLabel(item) {
      return this.service === "meal"
        ? this.formatMealName(item)
        : this.formatToTitleCase(item).replace("Rooms", "Rooms /");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  color: #21312a;
}
.item {
  width: 100%;
  margin-bottom: 30px;

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }

  &-title {
    display: flex;
    align-items: center;
    width: 70%;

    img {
      height: 48px;
      width: 48px;
      border-radius: 6px;
      margin-right: 15px;
      object-fit: contain;
      background: #ece8e6;
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--eden-grey-secondary);
    }
  }

  &-rating {
    img {
      height: 22px !important;
      width: 22px !important;
    }

    .eden-custom--radio .el-radio {
      padding: 5px;
    }
  }

  &-extra--feedback {
    p {
      color: var(--eden-grey-secondary);
      font-size: 0.8rem;
    }
    span {
      font-size: 0.8rem;
      color: var(--eden-orange-primary);
      cursor: pointer;
    }
  }
}

.general-experience {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .eden-custom--radio {
    display: flex;

    img {
      height: 40px;
      width: 40px;
    }
  }
}
</style>
