<template>
  <div>
    <el-form :model="form" label-position="top">
      <el-row v-if="service === 'laundry'" type="flex">
        <el-col :span="24">
          <el-form-item label="Laundry pickup date">
            <el-date-picker
              v-model="form.pickup_date"
              type="date"
              format="ddd, dd MMM, yyyy"
              value-format="yyyy-MM-dd"
              :clearable="false"
              prefix-icon="eden-icon-calendar"
              :disabled="disableUpdate"
              :picker-options="{
                disabledDate: disabledDates,
              }"
              @change="showConfirmPickup = true"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item :label="formLabels[service]">
            <el-date-picker
              v-model="form.delivery_date"
              :clearable="false"
              type="date"
              format="ddd, dd MMM, yyyy"
              value-format="yyyy-MM-dd"
              prefix-icon="eden-icon-calendar"
              :disabled="disableUpdate"
              :picker-options="{
                disabledDate: disabledDates,
              }"
              @change="showConfirmDelivery = true"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex" v-if="order.is_gifted === true">
        <el-col :span="24">
          <el-form-item label="Gifted by" class="gifting">
            <p class="text-cursor text-primary" @click="getCustomer">
              {{order.gifted_by}}
            </p>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="24" class="el-form-item">
          <order-audits
            :recent-update="{
              date: order.updated_at,
              name: order.recently_updated_by,
            }"
          />
        </el-col>
      </el-row>
    </el-form>
    <eden-confirm-dialog
      title="Change Order Pickup Date"
      button-type="success"
      button-text="Change date"
      :show.sync="showConfirmPickup"
      @confirm="changePickupDate"
      @cancel="resetPickupDate"
    >
      <p>Are you sure you want to change this order's pickup date?</p>
    </eden-confirm-dialog>
    <eden-confirm-dialog
      title="Change Order Date"
      button-text="Change date"
      button-type="success"
      :show.sync="showConfirmDelivery"
      @confirm="changeDeliveryDate"
      @cancel="resetDeliveryDate"
    >
      <p>Are you sure you want to change this order's delivery date?</p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import orders from "@/requests/orders/order";
import OrderAudits from "@/components/Orders/Order/OrderAudits";

export default {
  name: "OrderSchedule",
  components: { OrderAudits },
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    disableUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        pickup_date: "",
        delivery_date: "",
      },
      formLabels: {
        beauty: "Beauty SP arrival date",
        cleaning: "Cleaning date",
        laundry: "Laundry delivery date",
        meal: "Meal delivery date",
      },
      showConfirmPickup: false,
      showConfirmDelivery: false,
    };
  },
  computed: {
    service() {
      return this.order.service.toLowerCase().includes("meal")
        ? "meal"
        : this.order.service.toLowerCase();
    },
    orderId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.form.pickup_date = this.order.pickup_from_customer_date;
    this.form.delivery_date = this.order.deliver_to_customer_date;
  },
  methods: {
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin", "admin", "gardener_pro"])) {
        return false;
      }

      const date = this.formatDate(time, "y-m-d");
      const yesterday = this.formatPeriodDate("yesterday", "y-m-d");
      const tomorrow = this.formatPeriodDate("tomorrow", "y-m-d");
      const nexttwodays = this.formatPeriodDate("nexttwodays", "y-m-d");
      const lastServiceDate = this.formatDate(
        this.order.next_billing_date,
        "y-m-d",
      );

      const afterNextCharge = date > lastServiceDate;
      const isCurrentCycle = !!this.order.is_current_cycle;

      if (this.allowAccessFor(["operations", "kitchen"]) && date >= yesterday) {
        return isCurrentCycle ? afterNextCharge : false;
      }

      // Lower Limit
      if (date < nexttwodays) {
        return true;
      }

      // Upper Limit
      if (this.order.is_current_cycle && afterNextCharge) {
        return true;
      }

      return date < tomorrow;
    },
    changePickupDate() {
      const payload = {
        date: this.form.pickup_date,
        type_of_date: "pickup_from_customer",
      };

      orders
        .pickup(this.orderId, payload)
        .then((response) => {
          if (response.data.status) {
            const newDeliveryDate = new Date(this.form.pickup_date);
            newDeliveryDate.setDate(newDeliveryDate.getDate() + 2);

            this.form.delivery_date = newDeliveryDate;
            this.changeDeliveryDate();

            this.showConfirmPickup = false;
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.showConfirmPickup = false;
        });
    },
    resetPickupDate() {
      this.form.pickup_date = this.order.pickup_from_customer_date;
    },
    changeDeliveryDate() {
      const payload = {
        date: this.form.delivery_date,
        type_of_date: "deliver_to_customer",
      };
      orders
        .delivery(this.orderId, payload)
        .then((response) => {
          if (response.data.status) {
            this.showConfirmDelivery = false;
            this.$message.success(response.data.message);
            this.$emit("date-changed");
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.showConfirmDelivery = false;
        });
    },
    resetDeliveryDate() {
      this.form.delivery_date = this.order.deliver_to_customer_date;
    },
    getCustomer() {
      this.$router.push({
        name: "customers.individual",
        params: {
          id: this.order.gifter_user_id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
    padding: 0 16px;
}
.gifting {
  border-top: 1px solid #F0F4F2;
  border-bottom: 1px solid #F0F4F2;
  padding: 16px;
}
</style>
