<template>
  <div>
    <el-button
      class="mr-1"
      type="primary"
      plain
      size="medium"
      @click="showConfirm = true"
      >Mark as Complete</el-button
    >
    <eden-confirm-dialog
      title="Complete Order"
      :button-text="'Complete'"
      :button-type="'success'"
      :button-status="completing"
      :show.sync="showConfirm"
      @confirm="complete"
    >
      <p>Are you sure you want to mark this order as completed?</p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import order from "@/requests/orders/order";

export default {
  name: "OrderCompletion",
  props: {
    orderId: {
      type: [String, Number],
      default: "",
    },
    orderStatus: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showConfirm: false,
      completing: false,
    };
  },
  methods: {
    complete() {
      this.completing = true;
      const payload = {
        completed_status: "complete",
      };
      order
        .complete(this.orderId, payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.showConfirm = false;
            this.completing = false;
            this.$emit("success");
          }
        })
        .catch((error) => {
          this.completing = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss"></style>
