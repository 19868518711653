import axios from "axios";

export default {
  reasons(service) {
    return axios.get(`feedback/service/${service}/ratings_with_reasons`);
  },

  allReasons() {
    return axios.get("feedback/services/reasons");
  },

  add(payload) {
    return axios.post("feedback/service/rating/add", payload);
  },

  get(id) {
    return axios.get(`feedback/service/ratings/${id}`);
  },
};
