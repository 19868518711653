<template>
  <div>
    <eden-page-header
      :title="'Orders & Schedule'"
      :subtitle="`Order ${orderId}`"
    >
      <template slot="actions" v-if="!deleted">
        <el-dropdown @command="setOrderAction">
          <el-button type="plain">
            Actions <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="'report-issue'">
              Report Issue</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </eden-page-header>
    <eden-loader v-if="loading" />
    <div v-else class="order">
      <template v-if="order.service">
        <eden-information-card>
          <template slot="content">
            <div
              class="order-summary"
              :class="order.is_gifted === true ? 'margin-top' : ''"
            >
              <div class="gifted-order" v-if="order.is_gifted === true">
                Gifted service
              </div>
              <div class="order-summary__image" :class="service">
                <img :src="getServiceImage(`${service}`)" :alt="service" />
              </div>
              <div class="order-summary__details">
                <h6>{{ orderId }}</h6>
                <p class="text-" @click="getCustomer">
                  {{ customerName }}
                </p>
              </div>
              <div class="order-summary__tags">
                <order-tags
                  :order-status="orderStatus"
                  :order-current-cycle="order.is_current_cycle"
                  :order-onetime="order.is_one_time_order"
                  :order-complementary="order.is_complementary"
                />
              </div>
              <div class="order-summary__schedule">
                <order-schedule
                  :order="order"
                  :disable-update="disableUpdate"
                  @date-changed="getOrder"
                />
              </div>
              <div class="order-summary__actions" v-if="!deleted">
                <order-completion
                  v-if="order.completed_status === 'incomplete'"
                  :order-id="orderId"
                  :order-status="order.completed_status"
                  @success="getOrder"
                />
                <order-cancellation
                  v-if="allowAccessFor(['admin', 'operations', 'kitchen'])"
                  :order-id="orderId"
                  :service="service"
                />
              </div>
            </div>
          </template>
        </eden-information-card>
        <div class="order-body">
          <el-tabs v-model="tab" @tab-click="updateRouteQuery">
            <el-tab-pane label="Order" name="order">
              <component
                :is="`order-${component}`"
                :order="order"
                :disable-update="disableUpdate"
                @get-order="getOrder"
              ></component>
            </el-tab-pane>
            <el-tab-pane label="Feedback" name="feedback">
              <order-feedback :order="order" :service="component" />
            </el-tab-pane>
            <el-tab-pane name="problem-log">
              <span class="is-flex is-align-center" slot="label">
                Issues Log
                <span v-if="issuesLog" class="log-icon"></span>
              </span>
              <order-issues-log
                ref="issueLog"
                :order-id="order.order_id"
                :service="service"
                @logged="issuesLog = true"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </template>
      <template v-else>
        <div class="empty-order">
          <eden-content-empty :text="'Order Does Not Exist'" />
        </div>
      </template>
    </div>
    <issue-form
      :show.sync="showIssueForm"
      :order-specific="true"
      :order="order"
      @success="$refs.issueLog.getIssuesTracker()"
    />
  </div>
</template>

<script>
import OrderTags from "@/components/Orders/Order/OrderTags";
import OrderSchedule from "@/components/Orders/Order/OrderSchedule.vue";

import OrderCompletion from "@/components/Orders/Order/Actions/OrderCompletion";
import OrderCancellation from "@/components/Orders/Order/Actions/OrderCancellation";

import OrderFeedback from "@/components/Orders/Order/Feedback/OrderFeedback";
import OrderIssuesLog from "@/components/Orders/Order/OrderIssuesLog";

import IssueForm from "@/components/Feedback/Issues/IssueForm";

import order from "@/requests/orders/order";
import laundry from "@/requests/orders/laundry";
import * as actions from "@/store/action-types";

export default {
  name: "Order",
  components: {
    IssueForm,
    OrderCompletion,
    OrderCancellation,
    OrderSchedule,
    OrderTags,
    OrderBeauty: () => import("@/components/Orders/Order/Beauty/OrderBeauty"),
    OrderCleaning: () =>
      import("@/components/Orders/Order/Cleaning/OrderCleaning"),
    OrderLaundry: () =>
      import("@/components/Orders/Order/Laundry/OrderLaundry"),
    OrderMeal: () => import("@/components/Orders/Order/Meal/OrderMeal"),
    OrderFeedback,
    OrderIssuesLog,
  },
  data() {
    return {
      tab: "order",
      loading: false,
      order: {},
      customerReport: false,
      issuesLog: false,
      showConfirmDialog: false,
      showIssueForm: false,
    };
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    orderStatus() {
      return this.order.completed_status;
    },
    service() {
      return this.order.service && this.order.service.toLowerCase();
    },
    component() {
      return this.service.includes("meal") ? "meal" : this.service;
    },
    deleted() {
      return this.order.customer_email === null ? true : false;
    },
    customerName() {
      return this.formatName(this.order.customer_name);
    },
    customerId() {
      return this.order.customer_id;
    },
    customerEmail() {
      return this.order.customer_email.toLowerCase();
    },
    disableUpdate() {
      if (this.deleted) {
        return true;
      }
      if (this.allowAccessFor(["admin", "gardener_pro"])) {
        return false;
      }

      const { deliver_to_customer_date: delivery } = this.order;
      const currentTime = this.formatTime("now", "24h");

      const currentDate = this.formatDate(new Date(), "y-m-d");
      const currentDay = this.getWeekday(new Date());
      const yesterday = this.formatPeriodDate("yesterday", "y-m-d");

      const deliveryDay = this.getWeekday(delivery);
      const deliveryDate = this.formatDate(delivery, "y-m-d");

      const daysToDelivery = this.getDateDifference({
        fDate: deliveryDate,
        sDate: currentDate,
        unit: "day",
      });

      const isDeliveryDayToday = deliveryDate === currentDate;
      const isDeliveryYesterday = deliveryDate === yesterday;

      const isDeliveryPast = deliveryDate < currentDate;

      const roles = ["admin", "operations", "kitchen"];

      if (isDeliveryDayToday || isDeliveryYesterday) {
        return !this.allowAccessFor(roles);
      }

      if (isDeliveryPast) {
        return true;
      }

      if (
        currentDay === "Saturday" &&
        deliveryDay === "Monday" &&
        daysToDelivery === 2
      ) {
        return !this.allowAccessFor(roles);
      }

      if (daysToDelivery === 1) {
        return !this.allowAccessFor(roles);
      }

      if (daysToDelivery === 2) {
        if (currentTime >= "20:00") {
          return !this.allowAccessFor([roles]);
        }
      }

      return false;
    },
  },
  created() {
    this.getServicePartners();
    this.getOrder();
    let { t } = this.$route.query;
    this.tab = t ? t : "order";
  },
  methods: {
    updateRouteQuery(tab) {
      const { t } = this.$route.query;
      if (t === this.tab) {
        return false;
      }
      this.$router.push({
        name: "orders.order",
        params: { id: this.orderId },
        query: { t: tab.name },
      });
    },
    getServicePartners() {
      this.$store.dispatch(actions.GET_SERVICE_PARTNERS_LIST);
    },
    getOrder() {
      this.loading = true;
      order
        .get(this.orderId)
        .then((response) => {
          if (response.data.status) {
            this.order = response.data.order;
            console.log(this.order);
            if (this.service === "laundry") {
              this.getLaundryOrderTally();
            } else {
              this.loading = false;
            }
          }
        })
        .catch((error) => {
          const { message, data } = error.response.data;
          if (data) {
            this.$message.info(message);
            this.createOrder(data);
          } else {
            this.$message.error(message);
            this.loading = false;
          }
        });
    },
    createOrder(data) {
      const payload = {
        service: data.service,
        customer_email: data.customer_email,
        order_id: this.orderId,
      };

      order
        .create(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success("Preference created successfully!");
            this.getOrder();
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
          this.loading = false;
        });
    },
    getLaundryOrderTally() {
      laundry
        .tallyGet(this.orderId)
        .then((response) => {
          const { status, data } = response.data;
          if (status && data) {
            this.order.items_tally = this.sortArray({
              data: data.tally_items,
              property: "name",
            });
          } else {
            this.order.items_tally = [];
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getCustomer() {
      this.$router.push({
        name: "customers.individual",
        params: {
          id: this.order.customer_id,
        },
      });
    },
    setOrderAction(action) {
      if (action === "report-issue") {
        this.showIssueForm = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.information-card {
  height: 100%;
  min-width: 280px;
}

.order {
  display: flex;

  .order-summary {
    color: #0f241b;

    &__image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 10px;
      background-color: var(--eden-grey-senary);

      &.beauty {
        background-color: var(--eden-pink-senary);
      }

      &.laundry {
        background-color: var(--eden-purple-septenary);
      }

      &.cleaning {
        background-color: var(--eden-orange-septenary);
      }

      &.dailymeal,
      &.meal {
        background-color: var(--eden-green-senary);
      }

      img {
        width: 38px;
        height: 38px;
      }
    }

    &__details {
      text-align: center;
      margin-bottom: 16px;

      h6 {
        font-family: "CerebriSans", sans-serif;
        font-size: 1.125rem;
        margin-bottom: 7px;
        color: var(--eden-green-primary);
      }
    }

    &__tags {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
    }

    &__schedule {
      margin-bottom: 15px;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  &-body {
    $-summary-width: 400px;
    margin-left: 64px;
    width: calc(100% - #{$-summary-width});
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
    &-body {
      margin-left: 0;
      width: 100%;
    }
  }
}

.log-icon {
  height: 6px;
  width: 6px;
  border-radius: 100px;
  background: var(--eden-danger);
  margin-left: 5px;
  position: relative;
  top: -5px;
}

.empty-order {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.margin-top {
  margin-top: 50px;
}
.gifted-order {
  padding: 16px;
  position: absolute;
  text-align: center;
  width: 280px;
  border-radius: 16px 16px 0 0;
  top: 115px;
  background: #ebfaff;
}
</style>
